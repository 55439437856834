.separator {
  flex: 1;
  border-bottom: 1px solid black;
  margin: 0 10px;
}

.separator-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
